import React, { useState } from "react";
import "./projectPage.css";
import ProjectFilter from "../projects/projectFilter";
import ProjectCard from "../projects/projectCard";



const projects = [
  {
    id: "Snapper",
    title: "Snapper",
    topic: "Design",
    description: "A mobile app that connects scuba divers through virtual dive logging and real-time marine life reporting",
    image: "/assets/projects/snapper.png",
  },
  {
    id: "Armoire",
    title: "Armoire",
    topic: "Design",
    description: "A Pinterest-inspired mobile application for closet organization and outfit planning",
    image: "/assets/projects/amoire.png",
  },
  {
    id: "JuzDelights",
    title: "Juz Delights",
    topic: "Design",
    description: "An e-commerce website for healthy packaged meals",
    image: "/assets/projects/juz-delights2.png",
  },
  {
    id: "Collide",
    title: "Collide",
    topic: "Code",
    description: "A fun website designed for Scout's annual design conference",
    image: "/assets/projects/collide2.png",
  },
  // {
  //   id: 3,
  //   title: "Movie Mates",
  //   topic: "Code",
  //   image: "/assets/projects/movie_mates.png",
  // },
  // {
  //   id: 4,
  //   title: "Breaktime",
  //   topic: "Code",
  //   image: "/assets/projects/breaktimeSquare.png",
  // },
  {
    id: "TsengLab",
    title: "Tseng Lab Database",
    topic: "Code",
    description: "A database and visualizations for gene data",
    image: "/assets/projects/tseng.png",
  },
  {
    id: "StudentHub",
    title: "Student Hub Redesign",
    topic: "Design",
    description: "A mobile redesign of Northeastern's student hub",
    image: "/assets/projects/StudentHubRedesign.png",
  },
  {
    id: "NeighborhoodNav",
    title: "Neighborhood Navigator",
    topic: "Design",
    description: "A modern spin on finding new travel destinations",
    image: "/assets/projects/Neighborhood_nav2.png",
  },
  // Add more projects
];

const topics = ["All", "Data", "Design", "Code"]; // Define the topics

function ProjectSection() {
  const [selectedTopic, setSelectedTopic] = useState("All");
  //const categories = [...new Set(projects.map((p) => p.topic))];
  const filteredProjects =
    selectedTopic === "All"
      ? projects
      : projects.filter((project) => project.topic === selectedTopic);

      return (
        <section id="projects" className="projects-page">
          <div className="container">
            <div className="row project-main-container">
              <h1 className="col-12 project-title">Projects</h1>
    
              <div className="projects-container col-12">
                {/* Scrollable project grid with all projects */}
                <div className="project-grid scrollable-projects">
                  {filteredProjects.map((project) => (
                    <div key={project.id} className="project-card-wrapper">
                      <ProjectCard project={project} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      );
    }
    
    export default ProjectSection;