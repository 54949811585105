import "../projects.css";
import Timeline from "../timeline/snapperTimeline"; // Updated timeline import
import "bootstrap/dist/css/bootstrap.min.css";

function Snapper() {
  return (
    <div className="project-container">
      {/* <h1>Armoire</h1> */}
      {/* <img src="/assets/projects/armoire.png" alt="Armoire Project" /> */}

      <div className="banner">
        <img
          src="assets/projects/Snapper-banner.png" // Updated image source
          
          alt="Snapper Project Image"
          className="banner-image"
        />
      </div>

      <div className="content-section">
        <h1 style={{ textAlign: "start", fontSize: "50px" }}>Snapper</h1>
        <br />
        <div className="intro">
          <div className="col" style={{ marginRight: "15px" }}>
            <h2>Overview</h2>
            <h4 style={{ maxWidth: "90%", lineHeight: "1.5" }}>
            Snapper's purpose is to connect and educate Scuba Divers through virtual dive logging, real time marine life reporting and fish identification through a mobile app. It is designed to be social, so you can see what your friends and people in the area are seeing.
            </h4>

            <div className="badge-container">
              <span className="badge">UI/UX</span>
              <span className="badge">Prototyping</span>
              <span className="badge">User Research</span>
              <span className="badge">Figma</span>
            </div>
          </div>
          <div className="col">
            <h2>Quick Summary</h2>
            <h4
  style={{ maxWidth: "95%", lineHeight: "1.5", fontSize: "20px" }}
>
  • This project was a collaborative effort among a team of 4 designers over one semester.
</h4>

<h4
  style={{ maxWidth: "95%", lineHeight: "1.5", fontSize: "20px" }}
>
  • As part of the team, I contributed to specific areas of the project like the midifs and the final flow for creating a post, as well as the fish tagging feature.
</h4>




          
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <div className="content-section">
        <Timeline />
        <p></p>
      </div>
      
    </div>
  );
}
export default Snapper;
